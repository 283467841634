import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { ArrowRight } from 'components/icon/index'
import { color, fontFamily, rem, breakpoints } from 'variables/_index'

export interface ButtonLargeProps {
  readonly href: string
  readonly value: string
  readonly isArrow?: boolean
}

type ButtonLargePropsClassName = ButtonLargeProps & {
  readonly className: string
}

function Component({
  className,
  href,
  value,
  isArrow = true,
}: ButtonLargePropsClassName) {
  return (
    <Link className={className} to={href}>
      {value}
      {isArrow && <ArrowRight className="e-arrow" color={color.main} />}
    </Link>
  )
}

const StyledComponent = styled(Component)<ButtonLargePropsClassName>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  min-height: ${rem(140 / 2)};
  padding: ${rem(50 / 2)} ${rem(100 / 2)};
  border-radius: 200px;
  border: solid 2px ${color.main};
  background-color: #fff;
  font-family: ${fontFamily.zenKaku};
  font-size: ${rem(28 / 2)};
  font-weight: 500;
  color: ${color.main};
  text-decoration: none;
  transition: background-color 0.3s ease 0s;

  @media ${breakpoints.lg} {
    padding: ${rem(36)} ${rem(76)};
    min-height: ${rem(100)};
    font-size: ${rem(18)};
  }

  > .e-arrow {
    content: '';
    flex-shrink: 0;
    width: 1.175rem;
    height: 0.5rem;
    margin-left: 0.313rem;
    object-fit: contain;

    @media ${breakpoints.lg} {
      width: ${rem(23.51)};
      height: ${rem(10)};
    }
  }

  &:hover,
  &:focus {
    background-color: ${color.buttonActive};
  }
`

export const ButtonLarge: (props: ButtonLargeProps) => JSX.Element = StyledComponent

export default ButtonLarge
