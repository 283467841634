import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { navigate } from 'gatsby'
import { Illustration } from 'components/group'
import ButtonLarge from 'components/atoms/buttonLarge'
import { rem, breakpoints, fontFamily, color } from 'variables/_index'
import { siteStructure } from 'utils/site'

export interface RenewalTemplateProps {
  readonly noProps?: string
}

type RenewalTemplatePropsClassName = RenewalTemplateProps & {
  readonly className: string
}

function Component({ className }: RenewalTemplatePropsClassName) {
  setTimeout(() => {
    navigate(siteStructure.home.path)
  }, 15 * 1000)

  return (
    <div className={className}>
      <div className="e-illustration">
        <Illustration />
      </div>
      <div className="e-logo-content">
        <div className="e-logo-before">
          <div className="e-logo-image">
            <StaticImage
              src="../assets/images/renewal/logo-before.png"
              alt="Fitオンラインゼミ"
            ></StaticImage>
          </div>
          <div className="e-logo-word">は</div>
        </div>
        <div className="e-logo-after">
          <div className="e-logo-image">
            <StaticImage
              src="../assets/images/renewal/logo-after.png"
              alt="フィットネットスタディ"
            ></StaticImage>
          </div>
          <div className="e-logo-word">へ</div>
        </div>
        <div className="e-text">生まれ変わりました！</div>
      </div>
      <div className="e-button">
        <ButtonLarge href={siteStructure.home.path} value="新サイトへ移動する" />
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<RenewalTemplatePropsClassName>`
  padding-bottom: ${rem(50 / 2)};

  @media ${breakpoints.lg} {
    padding-bottom: ${rem(40)};
  }

  > .e-illustration {
    padding-top: ${rem(40 / 2)};
    padding-bottom: ${rem(30 / 2)};

    @media ${breakpoints.lg} {
      padding-top: ${rem(40)};
      padding-bottom: ${rem(30)};
    }
  }

  .e-logo-content {
    margin-top: ${rem(10 / 2)};
    font-family: ${fontFamily.zenKaku};
    font-size: ${rem(40 / 2)};
    font-weight: 500;
    color: ${color.main};

    @media ${breakpoints.lg} {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-top: ${rem(20)};
    }

    > .e-logo-before,
    > .e-logo-after {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > .e-logo-before {
      > .e-logo-image {
        width: ${rem(301 / 2)};
        margin-right: ${rem(20 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(283.84)};
          margin-right: ${rem(20)};
        }
      }

      > .e-logo-word {
      }
    }

    > .e-logo-after {
      margin-top: ${rem(50 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(0)};
        margin-left: ${rem(20)};
      }

      > .e-logo-image {
        width: ${rem(354.6 / 2)};
        margin-right: ${rem(20 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(288.18)};
          margin-right: ${rem(20)};
        }
      }

      > .e-logo-word {
      }
    }

    > .e-text {
      margin-top: ${rem(30 / 2)};
      width: 100%;
      text-align: center;

      @media ${breakpoints.lg} {
        margin-top: ${rem(20)};
      }
    }
  }

  > .e-button {
    margin-top: ${rem(50 / 2)};
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(40)};
    }
  }
`

export const RenewalTemplate: (props: RenewalTemplateProps) => JSX.Element =
  StyledComponent

export default RenewalTemplate
