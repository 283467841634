import React from 'react'
import { RenewalTemplate } from 'templates/renewalTemplate'
import Seo from 'components/seo'
import globalTitle from 'utils/globalTitle'

interface RenewalPageProps {
  location: Location
}

const RenewalPage = ({ location }: RenewalPageProps): JSX.Element => (
  <>
    <Seo
      title={`新サイトのご案内${globalTitle(location.pathname)}`}
      path={location.pathname}
    />
    <RenewalTemplate />
  </>
)

export default RenewalPage
